var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('icon-class-bar',{attrs:{"title":_vm.s_Title,"marks":true,"comments":true,"presence":true,"current":"comments"}}),_c('v-card-text',[_c('v-row',{staticClass:"ml-2",attrs:{"align":"start"}},[_c('v-col',[_c('v-switch',{attrs:{"color":"primary","value":"","input-value":_vm.getImpersonation.b_Status,"disabled":!_vm.getImpersonation.b_Status,"inset":"","label":_vm.$t('Surf as a student')},model:{value:(_vm.b_StatusImpersonation),callback:function ($$v) {_vm.b_StatusImpersonation=$$v},expression:"b_StatusImpersonation"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"11","sm":"5"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.a_Students,"loading":_vm.b_Loading,"item-key":"i_AddressRoleID + s_ClassGroupName","multi-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.s_Lastname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.s_Lastname)+" ")])])]}},{key:"item.s_Firstname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.s_Firstname)+" ")])])]}},{key:"item.s_ClassGroupName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.s_ClassGroupName)+" ")])])]}},{key:"item.s_Action",fn:function(ref){
var item = ref.item;
return [(_vm.displayImpersonate)?_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setImpersonated(item)}}},[_c('v-icon',{attrs:{"color":item.b_Impersonated ? 'orange' : 'grey'}},[_vm._v(" mdi-account-convert ")])],1)],1):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }