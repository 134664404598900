<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <icon-class-bar
          :title="s_Title"
          :marks="true"
          :comments="true"
          :presence="true"
          current="comments"
        />
        <v-card-text>
          <v-row
            align="start"
            class="ml-2"
          >
            <v-col>
              <v-switch
                v-model="b_StatusImpersonation"
                color="primary"
                value
                :input-value="getImpersonation.b_Status"
                :disabled="!getImpersonation.b_Status"
                inset
                :label="$t('Surf as a student')"
              ></v-switch>
            </v-col>
            <v-col
              class="d-flex"
              cols="11"
              sm="5"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.search')"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="headers"
            :items="a_Students"
            :loading="b_Loading"
            item-key="i_AddressRoleID + s_ClassGroupName"
            class="elevation-1 table-one"
            multi-sort
            dense
          >
            <template v-slot:item.s_Lastname="{item}">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Lastname }}
                </p>
              </div>
            </template>
            <template v-slot:item.s_Firstname="{item}">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Firstname }}
                </p>
              </div>
            </template>
            <template v-slot:item.s_ClassGroupName="{item}">
              <div class="d-flex align-center">
                <p class="ma-0 font-weight-medium">
                  {{ item.s_ClassGroupName }}
                </p>
              </div>
            </template>
            <template v-slot:item.s_Action="{item}">
              <div class="d-flex align-center" v-if="displayImpersonate">
                <v-btn 
                  icon
                  @click="setImpersonated(item)"
                >
                  <v-icon
                    :color="item.b_Impersonated ? 'orange' : 'grey'"
                  >
                    mdi-account-convert
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue'
  import { get } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'
  import { config } from '../../../data/config'
  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Schüler',
    },
    components: {
      IconClassBar,
    },
    data () {
      return {
        search: '',
        selected: [],
        headers: [
          {
            text: this.$t('general.lastname'),
            align: 'start',
            sortable: true,
            value: 's_Lastname',
          },
          {
            text: this.$t('general.firstname'),
            align: 'start',
            sortable: true,
            value: 's_Firstname',
          },
          {
            text: this.$t('classgroup'),
            align: 'start',
            sortable: true,
            value: 's_ClassGroupName',
          },
          {
            text: '',
            align: 'start',
            sortable: false,
            value: 's_Action',
          },
        ],
        a_Students: [],
        s_ClassName: '',
        s_ClassGroupName: '',
        b_Loading: true,
        i_TeacherAddressRoleID: 0,
        b_StatusImpersonation: false,
        a_TeacherAddressRoleID: []
      }
    },
    watch: {
      b_StatusImpersonation (val) {
        if (val === false) this.unsetImpersonation()
      }
    },
    beforeMount () {
      this.b_StatusImpersonation = this.getImpersonation.b_Status
      this.getClassStudents()
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
      s_Title () {
        return (this.s_ClassGroupName != '') 
        ? this.s_ClassName + ' - ' + this.s_ClassGroupName
        : this.s_ClassName
      },
      displayImpersonate () {
        if ((this.i_TeacherAddressRoleID !== null && this.i_TeacherAddressRoleID !== 0 && this.getImpersonation.b_Status
          && this.i_TeacherAddressRoleID === this.getImpersonation.i_AddressRoleID) ||
          this.i_TeacherAddressRoleID === this.$smt.getAddressRoleID(this.$route.meta.role) ||
          this.a_TeacherAddressRoleID.includes(this.$smt.getAddressRoleID(this.$route.meta.role))
        ) return true
        return false
      },
    },
    methods: {
      ...mapActions(['setImpersonation']),
      getClassStudents () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/class/${this.$route.params.id}/student`, {})
          .then(response => {
            this.s_ClassName = response.s_ClassName
            this.i_TeacherAddressRoleID = response.i_TeacherAddressRoleID
            this.a_TeacherAddressRoleID = response.a_TeacherAddressRoleID
            if (response.status === 401) {
              // this.signOut()
              // this.$smt.logout()
              return
            }
            this.a_Students = response.Students.sort((a, b) => a.s_Lastname > b.s_Lastname ? 1 : -1)
            this.b_Loading = false
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setImpersonated (Ao_Student) {
        this.a_Students.forEach(student => {
          student.b_Impersonated = false
        });
        Ao_Student.b_Impersonated = true
        this.b_StatusImpersonation = true
        this.setImpersonation({
          b_Status: true,
          i_AddressRoleID: Ao_Student.i_AddressRoleID,
          s_FullName: Ao_Student.s_Firstname + ' ' + Ao_Student.s_Lastname,
          s_Role: config.roles[0],
          a_PlanningPeriods: Ao_Student.a_PlanningPeriods
        })
      },
      unsetImpersonation() {
        this.setImpersonation({
          b_Status: false,
          i_AddressRoleID: null,
          s_FullName: null,
          s_Role: null,
          a_PlanningPeriods: []
        })
        this.b_StatusImpersonation = false
        this.a_Students.forEach(student => {
          student.b_Impersonated = false
        });
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
